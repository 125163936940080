import React from 'react';
import Layout from '../components/layout';

export function FC() {
  return (
    <Layout>
      <div style={{ fontSize: '32px', textAlign: 'center' }}>
        <div>CasAttack512</div>
        <div>8078 5301 2925</div>
      </div>
    </Layout>
  );
}

export default FC;
